import React, { createContext, useState } from 'react';

// Create the ImageContext
export const ImageContext = createContext();

// Create a provider component
export const ImageProvider = ({ children }) => {
    const [images, setImages] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [sortOption, setSortOption] = useState('newest');

    // Function to add a batch of images
    const addImages = (newImages) => {
        //const combinedImages = [...images, ...newImages];
        //const sortedImages = sortImages(combinedImages);
        //setImages(sortedImages);
        setImages(newImages);
    };

    // Function to sort images based on the sortOption
    const sortImages = (imagesToSort) => {
        const sortedImages = [...imagesToSort];
        if (sortOption === 'newest') {
            sortedImages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } else if (sortOption === 'oldest') {
            sortedImages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        } else if (sortOption === 'mostLiked') {
            sortedImages.sort((a, b) => b.likes - a.likes);
        }
        return sortedImages;
    };

    return (
        <ImageContext.Provider value={{ images, addImages, hasMore, setHasMore, sortOption, setSortOption }}>
            {children}
        </ImageContext.Provider>
    );
};
