import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const UploadSuccess = ({ isOpen, closeModal }) => {
    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="custom-modal-content"
            className="custom-modal"
        >
            <div className="modal-content">
                <Modal.Header className="modal-header d-flex justify-content-between align-items-center">
                <Modal.Title className="label-text">Upload</Modal.Title>
                    <button
                            type="button"
                            className="close-btn"
                            onClick={closeModal}
                            aria-label="Close"
                        >
                            <FontAwesomeIcon icon={faTimes} />
                    </button>
                </Modal.Header>

                <Modal.Body className="modal-body text-center">
                    <div className="icon-container icon-container-upload">
                        <FontAwesomeIcon icon={faThumbsUp} size="4x" className="custom-icon-upload" />
                    </div>
                    <h4 className="custom-success-message">SUCCESS!</h4>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button onClick={closeModal} className="custom-button">
                        Done
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default UploadSuccess;
