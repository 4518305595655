import React from "react";
import "./roundedEdgeButton.css";

const RoundedEdgeButton = ({ label, bulletColor, onClick, className }) => {
    return (
        <div className={`round-edge-button ${className}`} onClick={onClick}>
            <div
                className="bullet-indicator"
                style={{ backgroundColor: bulletColor }}
            ></div>
            <div className="round-edge-txt">{label}</div>
        </div>
    );
};

export default RoundedEdgeButton;
