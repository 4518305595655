import ProfileContent from '../components/Content/ContentProfile';
import Profile from '../components/Profile/Profile';

const ProfilePage = ({ user }) => {

    return (
        <div className="profile-page">
            <Profile />                    
            <ProfileContent user={user} />
        </div>

    );
};

export default ProfilePage;