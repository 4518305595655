import React from "react";
import { CloseButton } from "./CloseButton";
import "./buttonContainer.css";

const ButtonContainer = ({ title, onClose }) => {
    return (
        <div className="button-container">
            <div className="header-content">
                <div className="text-wrapper">{title}</div>
                {/* Close Button aligned to the top right */}
                <CloseButton onClick={onClose} />
            </div>
        </div>
    );
};

export default ButtonContainer;
