import React, { useState, useEffect } from 'react';
import ImageModal from '../ImageModal/ImageModal'; // Assuming similar modal logic
import './Content.css'; // Reuse the same CSS for consistent styling
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

const ContentPublic = ({ userId }) => {
    const [userImages, setUserImages] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastKey, setLastKey] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null); // For handling modal

    useEffect(() => {
        const fetchUserImages = async () => {
            try {
                if (userId) {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/api/images/user-images?userId=${userId}&page=${currentPage}`
                    );
                    const fetchedImages = response.data.items;
                    setUserImages(prevImages => [...prevImages, ...fetchedImages]);
                    setLastKey(response.data.lastEvaluatedKey);

                    if (!response.data.lastEvaluatedKey) {
                        setHasMore(false); // No more images to fetch
                    }
                }
            } catch (error) {
                console.error('Error fetching user images:', error);
                setHasMore(false);
            }
        };

        fetchUserImages();
    }, [userId, currentPage]);

    const fetchMoreImages = () => {
        if (hasMore) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handleClick = async (item) => {
        try {
            // Fetch the user's profile data based on `userId` from the clicked item
            const response = await fetch(`https://3udlwodkp6.execute-api.us-east-1.amazonaws.com/prod/profile/${item.userId}`);
            const profileData = await response.json();

            // Format the upload date to MM/DD/YYYY
            const formattedDate = new Date(item.uploadedAt).toLocaleDateString("en-US");


            // Combine item data with profile data
            const combinedData = {
                ...item,
                creatorName: profileData.name || "Unknown",
                profileImageUrl: profileData.profilePicture || "",
                bio: profileData.bio || "",
                sub: profileData.sub || "",
                uploadedAt: formattedDate, // Use the formatted date
            };

            // Set the combined data as the selected item
            setSelectedItem(combinedData);

        } catch (error) {
            console.error("Error fetching profile data:", error);
            setSelectedItem(item); // Fall back to just the item data if fetch fails
        }
    };

    const handleCloseModal = () => {
        setSelectedItem(null); // Close modal
    };

    return (
        <section className="content-section">
            <InfiniteScroll
                dataLength={userImages.length}
                next={fetchMoreImages}
                hasMore={hasMore}
                loader={<h4>Loading more images...</h4>}
                endMessage={<p style={{ textAlign: 'center' }}><b>No more images to show</b></p>}
            >
                <div className="content-grid">
                    {userImages.map((item) => (
                        <div key={item.imageId} className="content-item" onClick={() => handleClick(item)}>
                            <div className="image-wrapper">
                                <img
                                    src={item.imageUrl}
                                    alt={item.title}
                                    className="content-image"
                                    loading="lazy"
                                />
                            </div>
                            <div className="content-info">
                                <p>{item.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </InfiniteScroll>

            {selectedItem && (
                //This is the information that gets passed to the modal.
                <ImageModal
                    image={selectedItem.imageUrl}
                    title={selectedItem.title}
                    description={selectedItem.description}
                    uploadDate={selectedItem.uploadedAt}
                    onClose={handleCloseModal}
                    creatorName={selectedItem.creatorName} // fetched from Cognito
                    profileImageUrl={selectedItem.profileImageUrl} // fetched from Cognito
                    username={selectedItem.sub} // fetched from Cognito
                />
            )}
        </section>
    );
};

export default ContentPublic;
