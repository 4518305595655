import React, { useState, useContext, useEffect } from 'react';
import ImageModal from '../ImageModal/ImageModal';
import './Content.css';
import { ImageContext } from '../../contexts/ImageContexts';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchImages as fetchImagesAPI } from '../../utils/api';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Content = ({ filterByUser = false }) => {
    const { images, addImages, hasMore, setHasMore } = useContext(ImageContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null);
    const [lastKey, setLastKey] = useState(null);

    const { user } = useAuthenticator((context) => [context.user]);

    useEffect(() => {
        if (!filterByUser || (filterByUser && user)) {
            fetchImages(1, 20, null, filterByUser, 'newest');
        }
    }, [filterByUser, user]);

    const handleClick = async (item) => {
        try {
            // Fetch the user's profile data based on `userId` from the clicked item
            const response = await fetch(`https://3udlwodkp6.execute-api.us-east-1.amazonaws.com/prod/profile/${item.userId}`);
            const profileData = await response.json();

            // Format the upload date to MM/DD/YYYY
            const formattedDate = new Date(item.uploadedAt).toLocaleDateString("en-US");


            // Combine item data with profile data
            const combinedData = {
                ...item,
                creatorName: profileData.name || "Unknown",
                profileImageUrl: profileData.profilePicture || "",
                bio: profileData.bio || "", 
                sub: profileData.sub || "",
                uploadedAt: formattedDate, // Use the formatted date
            };

            // Set the combined data as the selected item
            setSelectedItem(combinedData);

        } catch (error) {
            console.error("Error fetching profile data:", error);
            setSelectedItem(item); // Fall back to just the item data if fetch fails
        }
    };


    const handleCloseModal = () => {
        setSelectedItem(null);
    };

    const [isFetching, setIsFetching] = useState(false);

    const fetchImages = async () => {
        if (!hasMore || isFetching) {
            if (!hasMore) console.log("No more images to load.");
            if (isFetching) console.log("Already fetching images.");
            return;
        }

        setIsFetching(true);
        console.log('Before Fetch - Current Page:', currentPage);
        console.log('Before Fetch - Last Key:', lastKey);
        console.log('Before Fetch - Has More:', hasMore);

        try {
            const data = await fetchImagesAPI(currentPage, 50, lastKey, filterByUser); 
            console.log('Fetched Data:', data);

            if (data.items.length === 0) {
                console.log("No items returned, setting hasMore to false");
                setHasMore(false);
                return;
            }

            console.log('Fetched Images:', data.items);
            console.log('Existing Images:', images);
            const newImages = data.items.filter((image) => !images.some((img) => img.key === image.key));
            console.log('New Images After Filtering:', newImages);

            addImages(newImages);
            setLastKey(data.lastKey);
            //setLastKey(data.lastEvaluatedKey); // I had changed this and I can't remember whiy


            setCurrentPage((prevPage) => {
                const nextPage = prevPage + 1;
                console.log('Next Page:', nextPage);
                return nextPage;
            });

            console.log('New Images Added:', newImages.length);
            console.log('Updated Last Key:', data.lastEvaluatedKey);

            if (!data.lastEvaluatedKey) {
                setHasMore(false);
                console.log("No more data available, setting hasMore to false");
            }
        } catch (error) {
            console.error('Error fetching more images:', error);
            setHasMore(false);
        } finally {
            setIsFetching(false);
        }
    };

    return (
        <section className="content-section">
            <InfiniteScroll
                dataLength={images.length} // Ensure this reflects the sorted images
                next={fetchImages}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={<p style={{ textAlign: 'center' }}><b>No more images to show</b></p>}
            >
                <div className="content-grid">
                    {images.map((item, index) => (
                        <div key={item.imageId} className="content-item" onClick={() => handleClick(item)}>
                            <div className="image-wrapper">
                                <img
                                    src={item.imageUrl}
                                    alt={item.title}
                                    className="content-image"
                                    loading="lazy"
                                />
                            </div>
                            <div className="content-info">
                                <p>{item.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </InfiniteScroll>
           
            {selectedItem && (
                //This is the information that gets passed to the modal.
                <ImageModal
                    image={selectedItem.imageUrl}
                    title={selectedItem.title}
                    description={selectedItem.description}
                    uploadDate={selectedItem.uploadedAt}
                    onClose={handleCloseModal}
                    creatorName={selectedItem.creatorName} // fetched from Cognito
                    profileImageUrl={selectedItem.profileImageUrl} // fetched from Cognito
                    username={selectedItem.sub} // fetched from Cognito
                />
            )}

        </section>

    );
};

export default Content;
