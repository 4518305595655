import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import Home from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import PublicProfilePage from './pages/PublicProfilePage';
import Login from './components/Login/Login';
import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';
import UploadHandler from './components/UploadModal/UploadHandler';
import { ImageProvider } from './contexts/ImageContexts';
import { UserProvider } from './contexts/UserContext';
import { Authenticator } from '@aws-amplify/ui-react';

// Import global styles
import './styles/buttons.css';
import './styles/colors.css';
import './styles/typography.css';
import './styles/form-elements.css';
import './styles/icons.css';

Amplify.configure(awsmobile);

const App = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

    return (
        <Authenticator.Provider>
            <UserProvider>
                <ImageProvider>
                    <Router>
                        {/* Navigation Bar */}
                        <Navigation
                            onSignOut={() => setIsModalOpen(false)}
                            openUploadModal={() => setIsUploadModalOpen(true)}
                            onLogin={() => setIsModalOpen(true)}
                        />

                        {/* Routes */}
                        <Routes>
                            {/* Public Route: Home Page */}
                            <Route path="/" element={<Home />} />
                            <Route path="/profile/:username" element={<PublicProfilePage />} />

                            <Route
                                path="/profile"
                                element={
                                    <Authenticator loginMechanisms={['username']}>
                                        {({ user }) => {
                                            console.log("User object: ", user);  // Log the user object to inspect
                                            return user ? (
                                                <ProfilePage user={user} />
                                            ) : (
                                                <div>Please log in to view your profile.</div>
                                            );
                                        }}
                                    </Authenticator>
                                }
                            />
                        </Routes>

                        {/* Footer */}
                        <Footer />

                        {/* Upload Handler */}
                        <UploadHandler
                            isUploadModalOpen={isUploadModalOpen}
                            setIsUploadModalOpen={setIsUploadModalOpen}
                        />

                        {/* Login Modal */}
                        {isModalOpen && (
                            <Login isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
                        )}
                    </Router>
                </ImageProvider>
            </UserProvider>
        </Authenticator.Provider>
    );
};

export default App;
