import React from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './UploadPreview.css';

const UploadPreview = ({ isOpen, file, closeModal, onPublish }) => {
    const [title, setTitle] = React.useState("");
    const [category] = React.useState("all"); // Default to "all" without allowing changes
    const [description, setDescription] = React.useState("");

    if (!isOpen) return null;

    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="custom-modal-content"
            className="custom-modal"
        >
            <Modal.Header className="modal-header d-flex justify-content-between align-items-center">
                <Modal.Title className="label-text">Upload</Modal.Title>
                <button
                    type="button"
                    className="close-btn"
                    onClick={closeModal}
                    aria-label="Close"
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </Modal.Header>

            <Modal.Body className="custom-modal-body">
                <Row>
                    {/* Left Column: Title and Description */}
                    <Col md={6} className="d-flex flex-column">
                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Enter title"
                                maxLength={50} // Optional: Limit title length
                            />
                        </Form.Group>
                        <Form.Group className="flex-grow-1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Enter your description"                               
                                className="custom-textarea"
                            />
                        </Form.Group>
                    </Col>

                    {/* Right Column: Image Preview */}
                    <Col md={6}>
                        <div className="text-center">
                            <Form.Label className="d-block mb-2">This looks amazing!</Form.Label>
                            <div className="upload-preview-container">
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt="Preview"
                                    className="upload-preview-image"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <Button
                    onClick={() => onPublish({ title, category, description })}
                    className="custom-button"
                    disabled={!title || !file}
                >
                    Upload
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadPreview;
