import React from "react";
import { FaTimes } from 'react-icons/fa';
import "./closeButton.css";

export const CloseButton = ({ onClick }) => {
    return (
        <button className="close-button-unique" onClick={onClick}>
            <FaTimes className="close-icon-unique" />
        </button>
    );
};
