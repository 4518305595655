import React from 'react';
import './PublicBioSection.css';

const PublicBioSection = ({ bio }) => {
    return (
        <section className="bio-section">
            <h2 className="bio-header">Bio</h2>
            <div className="bio-content">
                <p>{bio}</p>
            </div>
        </section>
    );
};

export default PublicBioSection;
