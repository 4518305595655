import React, { useEffect } from 'react';
import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './Login.css';

const Login = ({ isOpen, onClose }) => {
    const { route } = useAuthenticator(context => [context.route]);

    // Close the modal automatically if the user has successfully signed in
    useEffect(() => {
        if (route === 'authenticated') {
            onClose(); // Close the modal once logged in
        }
    }, [route, onClose]);

    return (
        <Modal 
            show={isOpen}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="custom-modal-content"
            className="custom-modal"
            >

            <Modal.Header className="modal-header d-flex justify-content-between align-items-center">
                <Modal.Title className="label-text">Login</Modal.Title>
                
                {/* Close Button */}
                <button
                    type="button"
                    className="close-btn"
                    onClick={onClose}
                    aria-label="Close"
                >
                <FontAwesomeIcon icon={faTimes} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <Authenticator loginMechanisms={['username']} />
            </Modal.Body>
        </Modal>
    );
};

export default Login;
