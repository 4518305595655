import React from 'react';
import { Modal, ProgressBar, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import './UploadPreview.css'; 


const UploadProgress = ({ isOpen, progress, closeModal }) => {
    if (!isOpen) return null;

    return (
        <Modal 
            show={isOpen}
            onHide={closeModal}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="custom-modal-content"
            className="custom-modal"
            >
            <Modal.Header className="custom-modal-header">
                <Modal.Title className="custom-modal-title">Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body text-center">
                <div className="icon-container icon-container-upload mb-4">
                    <FontAwesomeIcon icon={faUpload} size="4x" />
                </div>
                <div className="custom-progress-bar">
                    <div className="custom-progress-bar-inner" style={{ width: `${progress}%` }}></div>
                </div>
                <p className="custom-text mt-3">Uploading...</p>
            </Modal.Body>
        </Modal>
    );
};

export default UploadProgress;
