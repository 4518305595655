import React, { useEffect, useState } from 'react';
import { fetchUserAttributes, updateUserAttributes, getCurrentUser } from '@aws-amplify/auth';
import ProfileBanner from '../ProfileBanner/ProfileBanner';
import ProfileControlBar from '../ProfileControlBar/ProfileControlBar';
import './Profile.css';

const Profile = () => {
    const [user, setUser] = useState(null);
    const [bio, setBio] = useState('');
    const [name, setName] = useState('');
    const [profilePicture, setProfilePicture] = useState('');
    const [banner, setBanner] = useState('');
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchAttributes = async () => {
            try {
                const currentUser = await getCurrentUser();
                setUser(currentUser);

                const attributes = await fetchUserAttributes();
                setBio(attributes['custom:bio'] || 'My Bio');
                setProfilePicture(attributes['custom:profilePicture'] || 'https://monstrouslegendscontentfd3f7-dev.s3.amazonaws.com/Volume%20(12).webp');
                setName(attributes['name'] || 'Monstrous me');
                // Encode the banner URL So it displays properly
                const rawBannerUrl = attributes['custom:banner'] || 'default-banner-url.jpg';
                const safeBannerUrl = rawBannerUrl.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29');
                setBanner(safeBannerUrl);
       
            } catch (error) {
                console.error('Error fetching user attributes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAttributes();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedAttributes = {
                'custom:bio': bio,
                'custom:profilePicture': profilePicture,
                'name': name,
                'custom:banner': banner,
            };

            await updateUserAttributes({
                userAttributes: updatedAttributes,
            });
            setIsEditing(false); // Close form after saving
            console.log('Profile updated successfully');
        } catch (error) {
            console.error('Error updating attributes:', error);
        }
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className="profile-container">
            <ProfileBanner profilePicture={profilePicture} name={name} banner={banner} />
            <ProfileControlBar
                isEditing={isEditing}
                onToggleEdit={isEditing ? handleSubmit : () => setIsEditing(true)} // Submit if editing, else toggle edit
            />
            {isEditing && ( // Show form only if editing
                <form onSubmit={handleSubmit} className="profile-form">
                    <div className="form-group">
                        <label>UserName</label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="profile-textarea" />
                    </div>
                    <div className="form-group">
                        <label>Profile Picture URL</label>
                        <input type="text" value={profilePicture} onChange={(e) => setProfilePicture(e.target.value)} className="profile-input" placeholder="Enter image URL" />
                    </div>
                    <div className="form-group">
                        <label>Banner URL</label>
                        <input type="text" value={banner} onChange={(e) => setBanner(e.target.value)} className="profile-input" placeholder="Enter banner URL" />
                    </div>
                    <div className="form-group">
                        <label>Bio</label>
                        <textarea value={bio} onChange={(e) => setBio(e.target.value)} className="bio-textarea" />
                    </div>
                    {/* Remove the Save button from here */}
                </form>
            )}
        </div>
    );
};

export default Profile;
