import React, { useEffect, useState } from 'react';
import Banner from '../components/Banner/Banner';
import Content from '../components/Content/Content';
import ControlBar from '../components/ControlBar/ControlBar';
import { fetchImages } from '../utils/api';

const HomePage = ({ sortOption, onSortChange }) => {
    const [images, setImages] = useState([]);
    const [lastKey, setLastKey] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Function to load images based on sortOption
        const loadImages = async () => {
            try {
                setIsLoading(true); // Start loading
                const data = await fetchImages(1, 20, null, false, sortOption);
                setImages(data.items); // Set fetched images
                setLastKey(data.lastEvaluatedKey); // Set the next pagination key if exists
            } catch (error) {
                console.error('Error loading images:', error);
            } finally {
                setIsLoading(false); // Stop loading
            }
        };

        // Load images whenever sortOption changes
        loadImages();
    }, [sortOption]);

    return (
        <div className="home-page">
            <Banner />
            <ControlBar sortOption={sortOption} onSortChange={onSortChange} />
            <Content images={images} isLoading={isLoading} />
        </div>
    );
};

export default HomePage;
