import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { FaUpload, FaUser } from 'react-icons/fa'; // Assuming you're using FontAwesome icons
import logo from '../../images/logo.png'; // Adjust the path as per your project structure
import './Navigation.css'; // Assuming you have this CSS file for styles

const Navigation = ({ openUploadModal, onLogin }) => {
    const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
    const navigate = useNavigate();

    const handleSignOut = async () => {
        try {
            await signOut();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <nav className="navbar">
            <div className="navbar-left">
                <Link to="/">
                    <img src={logo} alt="Monstrous Legends Logo" className="logo" />
                </Link>
            </div>
            <div className="navbar-right">
                {user ? (
                    <>
                        {/* Upload Icon */}
                        <FaUpload className="navbar-icon" onClick={openUploadModal} />
                        {/* Profile Icon */}
                        <Link to="/profile">
                            <FaUser className="navbar-icon" />
                        </Link>
                        {/* Logout Button */}
                        <button className="navbar-button" onClick={handleSignOut}>
                            Sign Out
                        </button>
                    </>
                ) : (
                    // Login Button if user is not logged in
                    <button className="navbar-button" onClick={onLogin}>
                        Login
                    </button>
                )}
            </div>
        </nav>
    );
};

export default Navigation;
