// PublicProfileControlBar.js
import React from 'react';
import './PublicProfileControlBar.css';

const PublicProfileControlBar = ({ isViewingBio, onToggleView }) => {
    return (
        <div className="profile-control-bar">
            <div className="control-bar-left"></div>
            <div className="control-bar-right">
                <button className="navbar-button" onClick={onToggleView}>
                    {isViewingBio ? 'View Library' : 'View Bio'}
                </button>
            </div>
        </div>
    );
};

export default PublicProfileControlBar;
