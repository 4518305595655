import React from "react";
import RoundedEdgeButton from "../interactives/RoundeEdgeButton";
import ButtonContainer from "../interactives/Buttoncontainer";
import "./categoryContainer.css";
import profilePic from "../../images/profilepic.png";

export const CategoryContainer = ({ onClose }) => {
    const categories = [
        { label: "Dragons", image: profilePic },
        { label: "Beasts", image: profilePic },
        { label: "Undead", image: profilePic },
        { label: "Humanoid", image: profilePic },
        { label: "Elementals", image: profilePic },
        { label: "Aliens", image: profilePic },
    ];

    return (
        <div className="category-column">
            <ButtonContainer title="Categories" onClose={onClose} /> {/* Use ButtonContainer for the title and close button */}
            <div className="small-icon-button">
                {categories.map((category, index) => (
                    <RoundedEdgeButton
                        key={index}
                        label={category.label}
                        backgroundImage={category.image}
                        onClick={() => console.log(`Selected ${category.label}`)}
                    />
                ))}
                <RoundedEdgeButton
                    label="Show All"
                    onClick={() => console.log('Show All')}
                />
            </div>
        </div>
    );
};

export default CategoryContainer;
