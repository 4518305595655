import React, { useState } from 'react';
import UploadModal from './UploadModal';
import UploadPreview from './UploadPreview';
import UploadProgress from './UploadProgress';
import UploadSuccess from './UploadSuccess';
import { uploadData } from '@aws-amplify/storage';
import { useAuthenticator } from '@aws-amplify/ui-react';

const UploadHandler = ({ isUploadModalOpen, setIsUploadModalOpen }) => {
    const { user } = useAuthenticator((context) => [context.user]);
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const [isUploadPreviewOpen, setIsUploadPreviewOpen] = useState(false);
    const [isUploadProgressOpen, setIsUploadProgressOpen] = useState(false);
    const [isUploadSuccessOpen, setIsUploadSuccessOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    // Handle the case where the user is not authenticated
    if (!user) {
        return null;
    }

    const handleFileSelect = (file) => {
        setSelectedFile(file);
        setIsUploadModalOpen(false);
        setIsUploadPreviewOpen(true);
    };

    const handlePublish = async ({ title, description, category }) => {
        try {
            // Ensure a file is selected
            if (!selectedFile) {
                alert('Please select a file before publishing.');
                return;
            }

            // Retrieve the user ID from the authenticated user object
            const userId = user?.attributes?.sub || user?.username || user?.userId;
            if (!userId) {
                alert('Failed to get user ID.');
                return;
            }

            // Close preview and show upload progress
            setIsUploadPreviewOpen(false);
            setIsUploadProgressOpen(true);

            // Upload the file to S3 with progress tracking
            const result = await uploadData({
                path: selectedFile.name,
                data: selectedFile,
                options: {
                    contentType: selectedFile.type,
                    progressCallback: (progress) => {
                        const percentCompleted = Math.round((progress.loaded / progress.total) * 100);
                        setUploadProgress(percentCompleted);
                    },
                    // Set the ACL for public read access
                    contentDisposition: 'public-read',
                },
            });

            const resolvedResult = await result.result;
            if (resolvedResult && resolvedResult.path) {
                const bucketName = "monstrouslegendscontentfd3f7-dev";
                const imageUrl = `https://${bucketName}.s3.amazonaws.com/${resolvedResult.path}`;

                // Prepare metadata payload
                const metadataPayload = {
                    userId: userId,
                    title: title,
                    description: description,
                    category: category,
                    imageUrl: imageUrl,
                };

                // Send metadata to backend to save in DynamoDB
                const saveMetadataResult = await saveMetadataToBackend(metadataPayload);

                if (saveMetadataResult) {
                    // Show success state
                    setIsUploadProgressOpen(false);
                    setIsUploadSuccessOpen(true);
                } else {
                    throw new Error('Failed to save metadata');
                }

            } else {
                throw new Error('Failed to determine the image URL');
            }

        } catch (error) {
            setIsUploadProgressOpen(false);
            console.error('Error during file upload or metadata saving:', error);
            alert('Failed to upload the file.');
        }
    };

    // Helper function to save metadata to DynamoDB through API
    const saveMetadataToBackend = async (metadataPayload) => {
        try {
            const response = await fetch(`${baseURL}/api/images/metadata`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(metadataPayload),
            });

            if (!response.ok) {
                console.error(`Failed to save metadata. Server returned ${response.status}`);
                return false;
            }
            return true;
        } catch (error) {
            console.error('Error during saving metadata:', error);
            return false;
        }
    };

    return (
        <>
            {isUploadModalOpen && (
                <UploadModal
                    isOpen={isUploadModalOpen}
                    closeModal={() => setIsUploadModalOpen(false)}
                    onFileSelect={handleFileSelect}
                />
            )}

            {isUploadPreviewOpen && (
                <UploadPreview
                    isOpen={isUploadPreviewOpen}
                    file={selectedFile}
                    closeModal={() => setIsUploadPreviewOpen(false)}
                    onPublish={handlePublish}
                />
            )}

            {isUploadProgressOpen && (
                <UploadProgress
                    isOpen={isUploadProgressOpen}
                    progress={uploadProgress}
                    closeModal={() => setIsUploadProgressOpen(false)}
                />
            )}

            {isUploadSuccessOpen && (
                <UploadSuccess
                    isOpen={isUploadSuccessOpen}
                    closeModal={() => setIsUploadSuccessOpen(false)}
                />
            )}
        </>
    );
};

export default UploadHandler;
