export const fetchImages = async (page = 1, limit = 5, lastKey = null, filterByUser = false, sortOption = 'newest') => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    console.log("API Base URL:", baseURL); // Debug log to verify environment variable

    if (!baseURL) {
        console.error("Base URL is not defined. Make sure you have set REACT_APP_API_BASE_URL in your .env file.");
        throw new Error("Base URL is undefined");
    }

    const endpoint = filterByUser
        ? `${baseURL}/api/user-images?page=${page}&limit=${limit}${lastKey ? `&lastKey=${lastKey}` : ''}&sortOption=${sortOption}`
        : `${baseURL}/api/images?page=${page}&limit=${limit}${lastKey ? `&lastKey=${lastKey}` : ''}&sortOption=${sortOption}`;

    try {
        const response = await fetch(endpoint);
        if (!response.ok) {
            throw new Error(`Failed to fetch images: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching images from backend:', error);
        throw error;
    }
};
