import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProfileBanner from '../components/ProfileBanner/ProfileBanner';
import ContentPublic from '../components/Content/ContentPublic';
import PublicProfileControlBar from '../components/PublicProfileControlBar/PublicProfileControlBar';
import PublicBioSection from '../components/PublicBioSection/PublicBioSection';

const PublicProfilePage = () => {
    const { username } = useParams(); // Get the username from the URL
    const [profileData, setProfileData] = useState(null);
    const [error, setError] = useState(null);
    const [viewBio, setViewBio] = useState(false); // New state to toggle between Bio and Content

    useEffect(() => {
        // Fetch profile data for the specified username
        async function fetchProfileData() {
            try {
                const response = await fetch(`https://3udlwodkp6.execute-api.us-east-1.amazonaws.com/prod/profile/${username}`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch profile data for ${username}`);
                }
                const data = await response.json();
                setProfileData(data);
            } catch (error) {
                console.error("Error fetching profile data:", error);
                setError(error.message);
            }
        }

        if (username) {
            fetchProfileData();
        }
    }, [username]);

    // Function to toggle between Bio and Content views
    const handleToggleView = () => {
        setViewBio((prevViewBio) => !prevViewBio);
    };

    // Show an error message if data fetch fails
    if (error) {
        return <div>Error: {error}</div>;
    }

    // Display a loading indicator if profile data is still loading
    if (!profileData) return <div>Loading...</div>;

    return (
        <div>
            <div className="profile-container">
                <ProfileBanner profilePicture={profileData.profilePicture} name={profileData.name} banner={profileData.banner} />
                <PublicProfileControlBar isViewingBio={viewBio} onToggleView={handleToggleView} />
                {viewBio ? (
                    <PublicBioSection bio={profileData.bio} />
                ) : (
                    <ContentPublic userId={profileData.sub} />
                )}
            </div>
        </div>
    );
};

export default PublicProfilePage;
