import React, { useState, useContext } from 'react';
import { FaFilter, FaSort } from 'react-icons/fa';
import './ControlBar.css';
import SortContainer from '../SortContainer/SortContainer';
import CategoryContainer from '../CategoryContainer/CategoryContainer';
import { ImageContext } from '../../contexts/ImageContexts';
import axios from 'axios';

const ControlBar = () => {
    const [showSortPanel, setShowSortPanel] = useState(false);
    const [showCategoryPanel, setShowCategoryPanel] = useState(false); // State to show/hide category panel
    const [sortedItems, setSortedItems] = useState('newest');
    const { addImages } = useContext(ImageContext);

    const goToDevBlog = () => {
        window.location.href = 'https://monstrouslegends.com/profile/c4884488-6081-709c-c02d-66fe02069dfc';
    };

    // Toggle the sort panel
    const handleSortClick = () => {
        setShowSortPanel((prevState) => !prevState);
        setShowCategoryPanel(false); // Close category panel if open
    };

    // Close the category panel
    const handleCloseCategoryPanel = () => {
        setShowCategoryPanel(false);
    };

    const handleSortChange = async (sortValue) => {
        try {
            const baseURL = process.env.REACT_APP_API_BASE_URL;
            console.log("API Base URL:", baseURL);

            // Use the existing /api/images route with sort parameters
            const response = await axios.get(`${baseURL}/api/images/sort`, {
                params: {
                    sortOption: sortValue,  // Pass the sorting option
                    category: 'all',        // Pass the category (if needed)
                }
            });

            console.log('API Response:', response.data);

            // Handle both possible structures (response.data.items or response.data)
            const images = Array.isArray(response.data.items) ? response.data.items : response.data;

            if (Array.isArray(images)) {
                const uniqueItems = images.filter((item, index, self) =>
                    index === self.findIndex((t) => t.imageId === item.imageId)
                );
                addImages(uniqueItems);
            } else {
                console.error("Invalid data format received from API");
                setSortedItems([]); // Reset to an empty array in case of error
            }
        } catch (error) {
            console.error("Failed to sort items:", error);
            setSortedItems([]); // Set to empty array on failure to avoid undefined state
        }
    };



    return (
        <div className="control-bar">
            <div className="control-bar-left">
                {/* COMMENTED OUT FILTER */}
                {/* <FaFilter className="control-bar-icon" onClick={handleFilterClick} /> */}
                <FaSort className="control-bar-icon" onClick={handleSortClick} />
            </div>

            {/* Render Category Panel */}
            {showCategoryPanel && (
                <div className="category-panel">
                    <CategoryContainer onClose={handleCloseCategoryPanel} />
                </div>
            )}

            {/* Render Sort Panel */}
            {showSortPanel && (
                <div className="sort-panel">
                    <SortContainer onSortChange={handleSortChange} onClose={() => setShowSortPanel(false)} />
                </div>
            )}

            <div className="control-bar-right">
                <button className="navbar-button" onClick={goToDevBlog}>
                    Dev Blog
                </button>
            </div>
        </div>
    );
};

export default ControlBar;
