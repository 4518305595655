import React from 'react';
import './ProfileBanner.css';

const ProfileBanner = ({ profilePicture, name, banner }) => {
    const rawBannerUrl = banner
    const safeBannerUrl = rawBannerUrl.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29');

    return (
        <div className="profile-header" style={{ backgroundImage: `url(${safeBannerUrl})` }}>
            <div className="group">
                <img src={profilePicture} alt="Profile" className="profile-picture" />
            </div>
            <div className="frame">
                <h1 className="artists-name">{name}</h1>
                {/*<p className="publishings">10000 Publications</p>
                <p className="sincedate">Since 2024</p>*/}
            </div>
        </div>
    );
};

export default ProfileBanner;
