// ProfileControlBar.js
import React from 'react';
import './ProfileControlBar.css';
import { FaFilter, FaSort } from 'react-icons/fa';

const ProfileControlBar = ({ isEditing, onToggleEdit }) => {
    return (
        <div className="profile-control-bar">
            <div className="control-bar-left">
                {/*<FaSort className="control-bar-icon" />*/}
            </div>
            <div className="control-bar-right">
                <button className="navbar-button" onClick={onToggleEdit}>
                    {isEditing ? 'Save' : 'Edit Bio'} {/* Toggle button text */}
                </button>
            </div>
        </div>
    );
};

export default ProfileControlBar;
