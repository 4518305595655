import React, { useRef, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import './UploadModal.css';

const UploadModal = ({ isOpen, closeModal, onFileSelect, onNext }) => {
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');

    // Allowed file types, max file size (2 MB), and max dimensions
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
    const maxFileSize = 2 * 1024 * 1024; // 2 MB in bytes
    const maxDimensions = { width: 2048, height: 2048 };

    const handleFileInput = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            // Check if the file type is allowed
            if (!allowedFileTypes.includes(selectedFile.type)) {
                setFile(null);
                setError('Only .png, .jpg, or .webp files are allowed.');
                return;
            }

            // Check if the file size is within the limit
            if (selectedFile.size > maxFileSize) {
                setFile(null);
                setError('File size should be less than 2 MB.');
                return;
            }

            // Check image dimensions
            const img = new Image();
            img.src = URL.createObjectURL(selectedFile);
            img.onload = () => {
                if (img.width > maxDimensions.width || img.height > maxDimensions.height) {
                    setFile(null);
                    setError(`Image dimensions should not exceed ${maxDimensions.width}x${maxDimensions.height} pixels.`);
                } else {
                    setFile(selectedFile);
                    setError('');
                    onFileSelect(selectedFile);
                }
                URL.revokeObjectURL(img.src); // Clean up memory
            };
        }
    };

    const triggerFileSelect = () => {
        fileInputRef.current.click();
    };

    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="custom-modal-content"
            className="custom-modal"
        >
            <Modal.Header className="modal-header d-flex justify-content-between align-items-center">
                <Modal.Title className="label-text">Upload</Modal.Title>

                {/* Close Button */}
                <button
                    type="button"
                    className="close-btn"
                    onClick={closeModal}
                    aria-label="Close"
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </Modal.Header>

            <Modal.Body className="custom-modal-body">
                <Row className="mb-3">
                    <Col md={12} className="text-center">
                        <p className="custom-text text-center">Accepts .jpg, .png, and .webp files under 2MB and 2048x2048 pixels</p>
                        <div className="icon-container icon-container-upload">
                            <FontAwesomeIcon
                                className="icon-upload"
                                icon={faUpload}
                                size="4x"
                                aria-label="Upload Icon"
                            />
                        </div>
                        <p className="text-wrapper mt-3 custom-text">Drag and drop your image to upload</p>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileInput}
                            aria-label="Select file to upload"
                        />
                        <button className="custom-button mt-3" onClick={triggerFileSelect}>
                            Select File
                        </button>
                        <p className="text-wrapper-2 mt-2 custom-text">Only .jpg, .png, or .webp files under 2MB. The max image can not exceed 2048 pixels.</p>

                        {/* Error message display */}
                        {error && <p className="error-message mt-2">{error}</p>}
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer className="custom-modal-footer">
                <button
                    className="custom-button"
                    onClick={() => file && onNext()}
                    disabled={!file}
                >
                    Next
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadModal;
